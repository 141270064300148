<template>
  <div class="profile">
    <ScrollNotice/>
    <div class="container">
      <el-card class="header">
        <el-row class="row" :gutter="10">
          <el-col :span="2" class="head text-center">
            <el-image class="head-image" :src="this.apiUrl + this.datas.pic" fit="fit"></el-image>
			  
            <div class="text-sm ">上次登录时间</div>
						<div class="text-sm">{{info.last_login}}</div>
          </el-col>

          <el-col :span="15" class="el-col-lg-offset-1">
            <el-row>
              <el-col :span="11">
									<h2>{{info.nickName}}</h2>（姓名：{{info.userName}}）（ID：{{info.ids}}）
				
				
                <el-form   label-width="100px" label-suffix=":">
                  <el-form-item v-if="type_user==2"  label="技术满意度">
                    <el-row>
                      <el-col :span="2">0</el-col>
                      <el-col :span="16" class="progress">
                        <el-progress :percentage="90" status="success" :show-text="false"></el-progress>
                      </el-col>
                      <el-col :span="6">4.5分</el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item v-if="type_user==2" label="文明满意度">
                    <el-row>
                      <el-col :span="2">0</el-col>
                      <el-col :span="16" class="progress">
                        <el-progress :percentage="90" status="success" :show-text="false"></el-progress>
                      </el-col>
                      <el-col :span="6">4.5分</el-col>
                    </el-row>
                  </el-form-item>
                  <el-button type="primary" v-if="type_user==1"  @click="goMessage()" >继续咨询</el-button>
                  <el-button type="primary" v-if="type_user==2" style="background-color: #2BA3A8 !important;">当面给病人看病</el-button>
                </el-form>
				
				
				
              </el-col>
              <el-col :span="13">
                <el-row>
                  <el-col :span="8">
                    <div class="message" @click="goMessage()">
											<h2>{{info.getEmailNewNum}}</h2>
											<div>未读消息</div>
                    </div>
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="8">
                    <div class="message">
                      <h2> 0 </h2>
                      <div>站内信</div>
                    </div>
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="8"  		@click="goLooks()"	  >
                    <div class="message">
                      
                        <h2> {{info.getLokeMe}} </h2>
                      <div>谁看过我</div>
                    </div>
                  </el-col>  
                </el-row>
                <el-divider></el-divider>
                <el-row>
                  <el-col :span="4">
                    <div class="message" @click="goMessage()">
											<h2>{{info.getEmailAllNum}}</h2>
											<div>收件箱</div>
                    </div> 
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                 <el-col :span="5">
                    <div class="message">
                      <h2>0</h2>
                      <div>我看过</div>
                    </div>
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="5">
                    <div class="message">
                  
                  	<h2>{{info.passive_collec_num}}</h2>
                  	<div>关注我</div>
                    </div>
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="5">
                    <div class="message">
					<h2>{{info.active_collec_num}}</h2>
                      <div>我关注</div>
                    </div>
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="5">
                    <div class="message" @click="goMySay()">
											<h2>{{info.evaluate_num}}</h2>
                      <div>对我的评论</div>
                    </div>
                  </el-col>
                </el-row>

              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-image :src="adImage"></el-image>
          </el-col>
        </el-row>
      </el-card>
      <center-index ref="centerIndex" class="margin-top margin-bottom"></center-index>
    </div>
  </div>

</template>

<script>


	import axios from "axios";
  import CenterIndex from "../children/center/Index";
  import ScrollNotice from "../../components/ScrollNotice";

  export default {
    name: "ProfileIndex",
    components: {
      CenterIndex, ScrollNotice,
    },
    data() {
      return {
				info: {},
		  type_user:0,
        headImg: require('../../assets/images/doctor.png'),
        adImage: require('../../assets/images/ad-image.png'),
		datas:{},
      }
    },
created(){
		
		this.type_user = sessionStorage.getItem('type');
		var query=this.$route.query;
		
		this.id = query.id; 
		
		const params = new URLSearchParams();
		params.append('uid',   sessionStorage.getItem('uid')); 
		console.log(params);
		axios.post(this.apiUrl+'/api/user/getInfo',params)
		.then((response) => {  
			this.datas = this.info =  response.data.data;   
		}) 
		.catch(function(error) {
		  console.log(error);
		});  
		   
	},
		computed: {
			
			goLooks(){
				
				this.$router.replace('/looks')
			},
			
			goMessage(){ 
				
				this.$router.replace('/inbox')
			},
			goMySay(){
				
				this.$router.replace('/mine/commentTo')
			}, 
		},
		mounted() {
		  this.$refs['centerIndex'].init();
		}
  }
</script>

<style scoped lang="scss">
  .container {
    width: 1200px;
    margin: auto;

    .header {
      /deep/ .el-card__body {
        padding: 30px !important;
        color: #999999;

        .head-image {
          width: 100px;
          height: 100px;
          background: #E6E4E6;
        }

        .text-sm {
          margin-top: 10px;
          font-size: 12px;
          width: 100px;
        }

        h2 {
          width: 80px;
          display: inline-block;
          margin: 0px;
          color: #000;
        }

        .el-form {
          margin-top: 10px;
          /*text-align: center;*/

          .el-button {
            width: 80%;
            border-radius: 5px !important;
            margin-top: 10px;
            margin-left: 10px;
          }

          .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
            margin-bottom: 0px;
          }

          .el-form-item {
            .el-form-item__label {
              text-align: left;
            }

            .progress {
              padding-top: 13px;
              padding-right: 10px;
            }


          }
        }

        .message {
          text-align: center;
          margin-top: 10px;
          width: 98%;
          float: left;

          h2 {
            margin: 5px 0px 10px;
            text-align: center;
            width: 100%;
          }

          div {
            padding-bottom: 20px;
            text-align: center;
            font-size: 13px;
          }

        }

        .el-divider--vertical {
          margin: 40px 0 0;
        }

        .el-divider--horizontal {
          margin: 5px 0;
        }

        &:hover {
          cursor: pointer;
        }


      }


    }

    .margin-top {
      margin-top: 34px;
    }
    .margin-bottom{
      margin-bottom: 34px;
    }
  }
</style>
